<template>
  <div class="van-main delivery-list">
    <div class="van-common-detail">
      <van-tabs v-model="active">
        <van-tab name="0" title="冻品">
          <van-search v-model="searchForm.cabinetNoOrName" left-icon="" placeholder="可输入柜号/货物名称进行搜索" show-action @search="onSearch">
            <template #action>
              <div @click="onSearch">
                搜索
              </div>
            </template>
          </van-search>
          <div>
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="finishedText"
                @load="onLoad"
              >
                <van-cell-group v-for="item in tableList" :key="item.id" @click="toDetail(item)">
                  <van-cell class="van-cell-headline">
                    <span>{{ `提货申请编号:${utils.isEffectiveCommon(item.applyNo)}` }}</span>
                    <span class="bt-left">{{ utils.statusFormat(_.toString(item.status),'StorageOutStatus') }}</span>
                  </van-cell>
                  <van-cell title="柜号：" :value="utils.isEffectiveCommon(item.cabinetNo)" />
                  <van-cell title="货物名称：" :value="utils.isEffectiveCommon(item.cargoName)" />
                  <van-cell title="提货类型：" :value="utils.statusFormat(_.toString(item.outType),'StorageOutType')" />
                  <van-cell title="提货方式：" :value="utils.statusFormat(_.toString(item.outMethod),'StorageOutMethod')" />
                  <van-cell title="申请提货总件数/重量（KG）：" :value="utils.isEffectiveCommon(item.applyNumber)" />
                  <van-cell title="仓库：" :value="utils.isEffectiveCommon(item.warehouseName)" />
                  <van-cell title="申请提货时间：" :value="utils.dataString(item.applyDate)" />
                </van-cell-group>
              </van-list>
            </van-pull-refresh>
          </div>
          <div class="button-fixed">
            <van-button type="info" block @click="$router.back()">
              返回
            </van-button>
          </div>
        </van-tab>
        <van-tab name="1" title="其他农产品">
          <van-search
            v-model="searchOtherForm.searchValue"
            shape="round"
            left-icon=""
            show-action
            placeholder="可输入合同编号、产品名称"
            @search="onOtherSearch"
          >
            <template #action>
              <div @click="onOtherSearch">
                搜索
              </div>
            </template>
          </van-search>
          <div>
            <van-pull-refresh v-model="refreshing" @refresh="onOtherRefresh">
              <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="otherFinishedText"
                @load="onLoad"
              >
                <div v-for="item in list" :key="item.id" class="delivery-item">
                  <p>
                    <span>
                      货物批次:
                    </span>
                    <span>
                      {{ utils.isEffectiveCommon(item.paymentApplicationNumber) }}
                    </span>
                    <span>
                      {{ utils.statusFormat(String(item.status), 'StorageOutStatus') }}
                    </span>
                  </p>
                  <p>
                    <span>
                      提单号:
                    </span>
                    <span>
                      {{ utils.isEffectiveCommon() }}
                    </span>
                  </p>
                  <p>
                    <span>
                      产品名称:
                    </span>
                    <span>
                      {{ utils.isEffectiveCommon(item.goodsName) }}
                    </span>
                  </p>
                  <p>
                    <span>
                      仓库名称:
                    </span>
                    <span>
                      {{ utils.isEffectiveCommon(item.warehouseName) }}
                    </span>
                  </p>
                  <p>
                    <span>
                      申请提货数量(KG):
                    </span>
                    <span>
                      {{ utils.moneyFormat(item.applyWeight) }}
                    </span>
                  </p>
                  <p>
                    <span>
                      提货联系人:
                    </span>
                    <span>
                      {{ utils.isEffectiveCommon(item.contacts) }}
                    </span>
                  </p>
                  <p>
                    <span>
                      提货单位:
                    </span>
                    <span style="width: 38  % !important;">
                      {{ utils.isEffectiveCommon(item.dsEnterpriseName) }}
                    </span>
                    <van-button plain round size="small" type="info" native-type="button" @click="detail(item)">
                      查看详情
                    </van-button>
                  </p>
                  <van-divider />
                </div>
              </van-list>
            </van-pull-refresh>
          </div>
          <div class="button-fixed">
            <van-button type="info" block @click="$router.back()">
              返回
            </van-button>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, List, PullRefresh, Cell, CellGroup, Search, Button } from 'vant'
export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button
  },
  data () {
    return {
      searchForm: {
        cabinetNoOrName: ''
      },
      searchOtherForm: {
        searchValue: '',
        pageNum: 1,
        pageSize: 10
      },
      finishedText: '没有更多了',
      active: '0',
      page: 1,
      tableList: [],
      loading: false,
      finished: false,
      refreshing: false,
      otherFinishedText: '没有更多了',
      newList: [],
      list: [],
      otherLoading: false,
      otherFinished: false,
      otherRefreshing: false
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.page = 1
      this.tableList = []
      this.onLoad()
    },
    onLoad () {
      this.searchForm.pageNum = this.page
      this.api.delivery.packExtractableListPage(this.searchForm).then(result => {
        const resData = this._.cloneDeep(result.data.value)
        this.page = resData.pageNum + 1
        if (this.refreshing) {
          this.tableList = []
          this.refreshing = false
        }
        if (resData.pages === 0 && this._.isEmpty(resData.list)) {
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          this.loading = false
          this.tableList = this._.concat(this.tableList, resData.list)
          if (resData.pageNum === resData.pages) {
            this.finished = true
            this.finishedText = '没有更多了'
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      })
      this.getList()
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.page = 1
      this.onLoad()
    },
    toDetail (row) {
      this.$router.push({
        path: '/delivery/record/detail',
        query: {
          stockOutId: row.stockOutId
        }
      })
    },
    onOtherSearch () {
      this.searchOtherForm.pageNum = 1
      this.otherFinishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.api.storageManagementByWeight.out.deliveryListPage(this.searchOtherForm).then(result => {
        this.newList = result.data.value
        if (this.newList.pages === 0 && this._.isEmpty(this.newList.list)) {
          this.list = []
          this.otherFinishedText = '暂无数据'
          this.otherFinished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.otherLoading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchOtherForm.pageNum += 1
          } else {
            this.otherFinished = true
          }
        }
      }).catch(() => {
        this.otherFinished = true
        this.refreshing = false
        this.otherFinishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onOtherRefresh () {
      // 清空列表数据
      this.otherFinished = false
      this.otherLoading = true
      this.searchOtherForm.pageNum = 1
      this.getList()
    },
    onOtherLoad () {
      this.getList()
    },
    detail (row) {
      this.$router.push({ name: 'deliveryDetailByWeight', query: { id: row.outId } })
    }
  }
}
</script>

<style lang="less" scoped>
  .van-main .van-main-search {
    top: 45px !important;
  }
  .delivery-list {
    .van-list {
      padding-bottom: 60px;
    }
    .delivery-item {
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 20px;
      position: relative;
      p {
        margin-left: 0px;
        >span {
          display: inline-block;
          // height: 20px;
          // line-height: 20px;
          vertical-align: text-top;
        }
        >span:nth-child(odd) {
          width: 35%;
          text-align: right;
          // overflow: hidden;
        }
        >span:nth-child(even) {
          margin-left: 5px;
          width: 40%;
          // overflow: hidden;
        }
      }
      p:first-child {
        >span:nth-child(even) {
          width: 40%;
        }
        >span:nth-child(3) {
          width: 20%;
          color: #F59A23;
          font-size: 13px;
        }
      }
      p:nth-child(6) {
        >span:nth-child(even) {
          width: 40%;
        }
      }
      button {
        position: absolute;
        height: 30px;
        right: 15px;
        bottom: 0;
      }
      .van-divider {
        margin-top: 40px;
      }

    }
  }
</style>
