var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "van-main delivery-list" }, [
    _c(
      "div",
      { staticClass: "van-common-detail" },
      [
        _c(
          "van-tabs",
          {
            model: {
              value: _vm.active,
              callback: function($$v) {
                _vm.active = $$v
              },
              expression: "active"
            }
          },
          [
            _c(
              "van-tab",
              { attrs: { name: "0", title: "冻品" } },
              [
                _c("van-search", {
                  attrs: {
                    "left-icon": "",
                    placeholder: "可输入柜号/货物名称进行搜索",
                    "show-action": ""
                  },
                  on: { search: _vm.onSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function() {
                        return [
                          _c("div", { on: { click: _vm.onSearch } }, [
                            _vm._v(" 搜索 ")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.searchForm.cabinetNoOrName,
                    callback: function($$v) {
                      _vm.$set(_vm.searchForm, "cabinetNoOrName", $$v)
                    },
                    expression: "searchForm.cabinetNoOrName"
                  }
                }),
                _c(
                  "div",
                  [
                    _c(
                      "van-pull-refresh",
                      {
                        on: { refresh: _vm.onRefresh },
                        model: {
                          value: _vm.refreshing,
                          callback: function($$v) {
                            _vm.refreshing = $$v
                          },
                          expression: "refreshing"
                        }
                      },
                      [
                        _c(
                          "van-list",
                          {
                            attrs: {
                              finished: _vm.finished,
                              "finished-text": _vm.finishedText
                            },
                            on: { load: _vm.onLoad },
                            model: {
                              value: _vm.loading,
                              callback: function($$v) {
                                _vm.loading = $$v
                              },
                              expression: "loading"
                            }
                          },
                          _vm._l(_vm.tableList, function(item) {
                            return _c(
                              "van-cell-group",
                              {
                                key: item.id,
                                on: {
                                  click: function($event) {
                                    return _vm.toDetail(item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "van-cell",
                                  { staticClass: "van-cell-headline" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "提货申请编号:" +
                                            _vm.utils.isEffectiveCommon(
                                              item.applyNo
                                            )
                                        )
                                      )
                                    ]),
                                    _c("span", { staticClass: "bt-left" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.utils.statusFormat(
                                            _vm._.toString(item.status),
                                            "StorageOutStatus"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c("van-cell", {
                                  attrs: {
                                    title: "柜号：",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.cabinetNo
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "货物名称：",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.cargoName
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "提货类型：",
                                    value: _vm.utils.statusFormat(
                                      _vm._.toString(item.outType),
                                      "StorageOutType"
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "提货方式：",
                                    value: _vm.utils.statusFormat(
                                      _vm._.toString(item.outMethod),
                                      "StorageOutMethod"
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "申请提货总件数/重量（KG）：",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.applyNumber
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "仓库：",
                                    value: _vm.utils.isEffectiveCommon(
                                      item.warehouseName
                                    )
                                  }
                                }),
                                _c("van-cell", {
                                  attrs: {
                                    title: "申请提货时间：",
                                    value: _vm.utils.dataString(item.applyDate)
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "button-fixed" },
                  [
                    _c(
                      "van-button",
                      {
                        attrs: { type: "info", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      },
                      [_vm._v(" 返回 ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "van-tab",
              { attrs: { name: "1", title: "其他农产品" } },
              [
                _c("van-search", {
                  attrs: {
                    shape: "round",
                    "left-icon": "",
                    "show-action": "",
                    placeholder: "可输入合同编号、产品名称"
                  },
                  on: { search: _vm.onOtherSearch },
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function() {
                        return [
                          _c("div", { on: { click: _vm.onOtherSearch } }, [
                            _vm._v(" 搜索 ")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.searchOtherForm.searchValue,
                    callback: function($$v) {
                      _vm.$set(_vm.searchOtherForm, "searchValue", $$v)
                    },
                    expression: "searchOtherForm.searchValue"
                  }
                }),
                _c(
                  "div",
                  [
                    _c(
                      "van-pull-refresh",
                      {
                        on: { refresh: _vm.onOtherRefresh },
                        model: {
                          value: _vm.refreshing,
                          callback: function($$v) {
                            _vm.refreshing = $$v
                          },
                          expression: "refreshing"
                        }
                      },
                      [
                        _c(
                          "van-list",
                          {
                            attrs: {
                              finished: _vm.finished,
                              "finished-text": _vm.otherFinishedText
                            },
                            on: { load: _vm.onLoad },
                            model: {
                              value: _vm.loading,
                              callback: function($$v) {
                                _vm.loading = $$v
                              },
                              expression: "loading"
                            }
                          },
                          _vm._l(_vm.list, function(item) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "delivery-item" },
                              [
                                _c("p", [
                                  _c("span", [_vm._v(" 货物批次: ")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            item.paymentApplicationNumber
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.statusFormat(
                                            String(item.status),
                                            "StorageOutStatus"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("span", [_vm._v(" 提单号: ")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.utils.isEffectiveCommon()) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("span", [_vm._v(" 产品名称: ")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            item.goodsName
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("span", [_vm._v(" 仓库名称: ")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            item.warehouseName
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("span", [_vm._v(" 申请提货数量(KG): ")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.moneyFormat(
                                            item.applyWeight
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c("p", [
                                  _c("span", [_vm._v(" 提货联系人: ")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            item.contacts
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]),
                                _c(
                                  "p",
                                  [
                                    _c("span", [_vm._v(" 提货单位: ")]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          width: "38  % !important"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.utils.isEffectiveCommon(
                                                item.dsEnterpriseName
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          round: "",
                                          size: "small",
                                          type: "info",
                                          "native-type": "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.detail(item)
                                          }
                                        }
                                      },
                                      [_vm._v(" 查看详情 ")]
                                    )
                                  ],
                                  1
                                ),
                                _c("van-divider")
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "button-fixed" },
                  [
                    _c(
                      "van-button",
                      {
                        attrs: { type: "info", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.back()
                          }
                        }
                      },
                      [_vm._v(" 返回 ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }